import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import styles from './vatSwitcher.scss';
import SwitcherIcon from '../../icons/switcherIcon';

import { IInjectedProps } from '../../../types';

const VatSwitcher = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const withVat = store?.orderModuleStore.withVat;

        if (store?.dataStore.contextData.customerData && store.dataStore.contextData.customerData.flagVatFree) {
            return null;
        }

        const buttonTestId = withVat ? 'inc-vat-button' : 'ex-vat-button';
        const buttonLabel = withVat ?
            store?.localization.withVatLocale : store?.localization.withoutVatLocale;

        function handleButtonClick() {
            const newWithVatValue = !withVat;
            store?.orderModuleStore.toggleWithVat();
            store?.orderModuleStore.setPriceModeCookie(newWithVatValue ? 'Gross' : 'Net');
        }

        const labelClassNames = classNames(styles.switcher_text, {
            [styles.withVat]: withVat,
        });
        const label = <span className={labelClassNames}>{buttonLabel}</span>;

        const buttonClassNames = withVat ? [styles.switcher_button, styles.withVat].join(' ') : styles.switcher_button;
        const iconClassNames = withVat ? [styles.switcher_icon, styles.withVat].join(' ') : styles.switcher_icon;

        return (
            <div data-testid='vat-switcher' className={styles.switcher}>
                <button
                    data-testid={buttonTestId}
                    className={buttonClassNames}
                    title={buttonLabel}
                    onClick={handleButtonClick}
                    ref={store.setFocusableElement}
                >
                    <span className={iconClassNames}>
                        <SwitcherIcon />
                    </span>
                </button>
                {label}
            </div>
        );
    })
);

export default VatSwitcher;
