import React, { useEffect, useRef, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { IInjectedProps } from '../../../../types';
import { SizeValue } from './sizeValue/sizeValue';
import styles from './sizeValues.scss';
import { ISalesArticleVariantSize } from '../../../../stores/types';
import { CustomSwiper } from '../customeSwiper/customeSwiper';
import SwiperCore from 'swiper';

export const SizeValues = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const availableSizes = store.variantStore.selectedSizeType?.sizes;
        const slidesPerColumn =
            store.getIsMobileView || availableSizes.length <= store.variantStore.defaultColumnsPerView ? 1 : 2;
        const tmpSelectedSizeIndex = availableSizes?.findIndex((size) => {
            return size.size.code === store.variantStore.selectedSize?.size.code;
        });
        const selectedSizeIndex = tmpSelectedSizeIndex ? tmpSelectedSizeIndex : 0;
        const wrapperRef = useRef<HTMLDivElement>(null);
        const [column, setColumn] = useState(1);
        const [maxWidth, setMaxWidth] = useState(0);
        const [calculated, setCalculated] = useState(false);

        useEffect(() => {
            if (wrapperRef.current) {
                store.setGroupElement(wrapperRef.current);
            }
        }, [])

        useEffect(() => {
            setCalculated(false);
            calculateWidth();
        }, [store.variantStore?.selectedSizeType.sizeTypeName]);

        function calculateWidth() {
            if (wrapperRef.current) {
                const elementArray = calculated ?
                    wrapperRef.current.children[1].children[0].children :
                    wrapperRef.current.querySelectorAll('div.om-size_value');
                const wrapperChildren = Array.from(
                    elementArray as HTMLCollectionOf<HTMLElement>
                );

                wrapperChildren.forEach(element => {
                    const tmp = calculated ? element.children[0] : element;
                    const sizeValue = tmp as HTMLElement;
                    sizeValue.style.width = 'auto';
                    sizeValue.style.minWidth = 'initial';
                    sizeValue.style.maxWidth = 'initial';
                });

                let maxChildWidth = 0;
                wrapperChildren.forEach(element => {
                    const tmp = calculated ? element.children[0] : element;
                    const sizeValue = tmp as HTMLElement;
                    if (sizeValue.offsetWidth > maxChildWidth) maxChildWidth = sizeValue.offsetWidth;
                });

                if (maxChildWidth < 50) maxChildWidth = 42;
                let tempColumn = 1;

                tempColumn = Math.floor((wrapperRef.current.clientWidth - 70) / (maxChildWidth + 8));
                if (tempColumn > 5) tempColumn = 5;
                setMaxWidth(maxChildWidth);
                setColumn(tempColumn);
                setCalculated(true);
            }
        }

        function renderArticleVariantSize(articleVariantSize: ISalesArticleVariantSize) {
            return (
                <SizeValue
                    size={articleVariantSize.size}
                    variantKey={articleVariantSize.salesArticleVariantKey}
                    key={articleVariantSize.size.name}
                    maxWidth={maxWidth}
                />
            );
        }

        const startSwipeCallBack = () => {
            store.variantStore.changeVisibleFitAccuracyPointer(false);
        };

        const endSwipeCallBack = (swiper: SwiperCore) => {
            store.variantStore.changeVisibleFitAccuracyPointer(true);
            store.variantStore.changeOffSetSizeIndex(swiper.realIndex);
        };

        return (
            <div data-testid='size_values' className={styles.size_values} ref={wrapperRef}>
                <CustomSwiper
                    type='size'
                    selectedElementIndex={selectedSizeIndex}
                    slidesPerColumn={slidesPerColumn}
                    columnsPerView={column}
                    enabled={calculated}
                    startSwipeCallBack={startSwipeCallBack}
                    endSwipeCallBack={endSwipeCallBack}
                    swipingEnabled={true}
                    setTabNavFocusable={store.setFocusableElement}
                >
                    {availableSizes.map(renderArticleVariantSize)}
                </CustomSwiper>
            </div>
        );
    })
);
