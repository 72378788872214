import React from 'react';
import { observer, inject } from 'mobx-react';
import { IInjectedProps } from '../../../../types';
import { SizeDimension } from './sizeDimension/sizeDimension';
import styles from './sizeDimensions.scss';

export const SizeDimensions = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const availableSizeTypes = store.variantStore.variantSelectionCopy.availableSizeTypes;
        const mapSizeTypes = sizeType => <SizeDimension sizeType={sizeType} key={sizeType.sizeTypeName} />;

        return (
            <>
                {availableSizeTypes && availableSizeTypes.length > 1 ? (
                    <div data-testid='size_dimensions' className={styles.size_dimensions} ref={store.setGroupElement}>
                        {availableSizeTypes.map(mapSizeTypes)}
                    </div>
                ) : null}
            </>
        );
    })
);
