import React from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import styles from './specialPanel.scss';
import { ISizeType, ISalesArticleVariantSize } from '../../../../stores/types';
import { IVariantStore } from '../../../../types/index';
import { CustomSwiper } from '../customeSwiper/customeSwiper';
import Length from '../../../icons/length';
import Diameter from '../../../icons/diameter';

export const SpecialPanel = inject('variantStore')(
    observer(({ variantStore }: IVariantStore) => {
        const tmpSelectedSizeTypeIndex = variantStore.variantSelectionCopy.availableSizeTypes.findIndex(
            sizeType => {
                return sizeType.sizeTypeCode === variantStore.selectedSizeType.sizeTypeCode;
            });
        const selectedSizeTypeIndex = tmpSelectedSizeTypeIndex ? tmpSelectedSizeTypeIndex : 0;
        const availableSizes = variantStore.selectedSizeType?.sizes;
        const columnPerView = variantStore.mainStore.getIsMobileView ? 4 : variantStore.defaultColumnsPerView;
        const availableSizeType = variantStore.variantSelectionCopy.availableSizeTypes;
        const sizeSlidesPerColumn = variantStore.mainStore.getIsMobileView ? 1 : 2;
        const sizeTypeSlidesPerColumn = variantStore.mainStore.getIsMobileView ||
            availableSizeType.length <= variantStore.defaultColumnsPerView ? 1 : 2;
        const tmpSelectedSizeIndex = availableSizes?.findIndex(size => {
            return size.size.code === variantStore.selectedSize?.size.code;
        });
        const selectedSizeIndex = tmpSelectedSizeIndex ? tmpSelectedSizeIndex : 0;

        const sizeTypeClick = (event: React.MouseEvent<HTMLDivElement>) => {
            const clickedSizeType = event.currentTarget.dataset.sizetype;
            if (clickedSizeType) variantStore?.changeSelectedSizeType(clickedSizeType);
        };

        const sizeClick = (event: React.MouseEvent<HTMLDivElement>) => {
            if (event.currentTarget.dataset.index) {
                const index = parseInt(event.currentTarget.dataset.index);
                const clickedSize = variantStore?.selectedSizeType?.sizes[index].size;
                if (clickedSize) variantStore?.changeSelectedSize(clickedSize);
            }
        };

        return (
            <div className={styles.special_panel}>
                <div data-testid='special-size-type' className={styles.special_content}>
                    <CustomSwiper
                        type='size'
                        selectedElementIndex={selectedSizeTypeIndex}
                        slidesPerColumn={sizeTypeSlidesPerColumn}
                        columnsPerView={columnPerView}
                        enabled={true}
                        swipingEnabled={true}
                        setTabNavFocusable={variantStore.mainStore.setFocusableElement}
                    >
                        {variantStore?.mainStore.dataStore.variantSelection.availableSizeTypes.map(
                            (sizeType: ISizeType, index) => (
                                <div
                                    key={index}
                                    className={classNames(styles.special_value, {
                                        [styles.selected]:
                                            variantStore?.selectedSizeType?.sizeTypeName === sizeType.sizeTypeName,
                                        [styles.sold_out]: !variantStore.anySizeExistForSizeType(sizeType),
                                    })}
                                    data-sizetype={sizeType.sizeTypeName}
                                    data-testid={sizeType.sizeTypeName}
                                    onClick={sizeTypeClick}
                                    ref={variantStore.anySizeExistForSizeType(sizeType) ?
                                        variantStore.mainStore.setFocusableElement : null}
                                >
                                    {sizeType.sizeTypeName}
                                </div>
                            )
                        )}
                    </CustomSwiper>
                </div>
                <div data-testid='subheader' className={classNames(styles.subheader, styles.subheader_mobile)}>
                    <Diameter />
                    <label>{variantStore.mainStore.localization.textDiameter}:
                        {' ' + variantStore?.selectedSizeType?.sizeTypeName}</label>
                </div>
                <div data-testid='special-size' className={styles.special_content}>
                    <CustomSwiper
                        type='size'
                        selectedElementIndex={selectedSizeIndex}
                        slidesPerColumn={sizeSlidesPerColumn}
                        columnsPerView={columnPerView}
                        enabled={true}
                        swipingEnabled={true}
                        setTabNavFocusable={variantStore.mainStore.setFocusableElement}
                    >
                        {variantStore?.selectedSizeType?.sizes.map((savSize: ISalesArticleVariantSize, index) => (
                            <div
                                key={index}
                                data-index={index}
                                data-testid={savSize.size.name}
                                className={classNames(styles.special_value, {
                                    [styles.selected]: variantStore?.selectedSize?.size.name === savSize.size.name,
                                    [styles.sold_out]: !savSize.salesArticleVariantKey,
                                })}
                                onClick={sizeClick}
                                ref={savSize.salesArticleVariantKey ?
                                    variantStore.mainStore.setFocusableElement : null}
                            >
                                {savSize.size.name}
                            </div>
                        ))}
                    </CustomSwiper>
                </div>
                <div data-testid='subheader' className={styles.subheader}>
                    <Length />
                    <label>{variantStore?.mainStore.localization.textLength}:
                        {' ' + variantStore?.selectedSize?.size.name}</label>
                </div>
            </div>
        );
    })
);
