import React from 'react';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../types';
import styles from './compareShareBookmark.scss';
import ShareIcon from '../../icons/shareIcon';

function CompareShareBookmarkButton(props: ICompareShareBookmarkButton) {
    const { icon, onClick, title, setTabNavFocusableElement } = props;
    return (
        <button onClick={onClick} title={title} ref={setTabNavFocusableElement}>
            {icon}
        </button>
    );
}

interface ICompareShareBookmarkButton {
    icon: React.ReactElement;
    title: string;
    onClick(): void;
    setTabNavFocusableElement(el: Element, order?: number): void;
}

export function CompareShareBookmarkButtonContainer(props: { children: JSX.Element;['data-testid']?: string }) {
    const [childHasButton, setChildHasButton] = React.useState(false);
    const containerRef = React.useRef<HTMLElement>();

    React.useEffect(() => {
        // search for fragment button and set flag
        if (containerRef.current) {
            let ref = containerRef.current;
            let searchingForButton = true;
            while (searchingForButton) {
                if (ref.firstElementChild) {
                    if (ref.firstElementChild?.tagName === 'BUTTON') {
                        searchingForButton = false;
                        setChildHasButton(true);
                    } else {
                        ref = ref.firstElementChild as HTMLElement;
                    }
                } else {
                    searchingForButton = false;
                    setChildHasButton(false);
                }
            }
        }
    }, []);

    return (
        <div
            data-testid={props['data-testid']}
            style={{ display: childHasButton ? 'flex' : 'none' }}
            ref={containerRef as React.LegacyRef<HTMLDivElement>}
            className={styles.compareShareBookmark_button_container}
        >
            {props.children}
        </div>
    );
}

const CompareShareBookmark = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const buttonContainerRef = React.useRef<HTMLDivElement>(null);
        const bookmarkRef = React.useRef<HTMLDivElement>(null);
        const mpcRef = React.useRef<HTMLDivElement>(null);

        React.useEffect(() => {
            // search for fragment button and set flag
            if (window.shell) {
                window.shell.publishTo('legacy.modals.openbyajax.ajaxfinished', null);
                if (buttonContainerRef.current) {
                    store.setGroupElement(buttonContainerRef.current);
                    window.shell.publishTo('ManualProductComparison.CreateCompareButtonTouchPoints',
                        buttonContainerRef.current);
                }


                window.shell.subscribeTo('ESCID.ESPP.Bookmark.RequestQuickOrderModalClose', () => {
                    store.scrollLock.isLocked && store.scrollLock.unlock();
                }, 'ESCID.ESPP.Bookmark.RequestQuickOrderModalClose');
            }

            // Timeout to add tabnav to fragment buttons
            setTimeout(() => {
                if (showBookmark && bookmarkRef && bookmarkRef.current && bookmarkRef.current.firstElementChild) {
                    store.setFocusableElement(bookmarkRef.current.firstElementChild);
                }

                if (store.dataStore.contextData.mpcIsAlive && buttonContainerRef && buttonContainerRef.current) {
                    for (let i=0; i < buttonContainerRef.current.children.length; i++) {
                        const child = buttonContainerRef.current.children[i];
                        if(child.className.indexOf('mpc-scope') > -1 && child.firstElementChild) {
                            store.setFocusableElement(child.firstElementChild);
                        }
                    }
                }
            }, 500);
        }, []);


        const handleShareClick = store?.orderModuleStore.handleShareClick;

        const showBookmark = !store.isGlobal && store.dataStore.contextData.bookmarkIsAlive;

        // TODO: do not show share button in Kiosk
        return (
            <div
                ref={buttonContainerRef}
                data-testid='compareShareBookmark-desktop-container'
                data-selected-colorid={store.dataStore.orderModule.articleInfo.salesArticleVariantKey}
                className={styles.compareShareBookmark_container}
            >
                <CompareShareBookmarkButtonContainer data-testid='share-button'>
                    <CompareShareBookmarkButton
                        icon={<ShareIcon />}
                        onClick={handleShareClick}
                        setTabNavFocusableElement={store.setFocusableElement}
                        title={store.localization.shareButtonLocale}
                    />
                </CompareShareBookmarkButtonContainer>
                { showBookmark && <div className='hidden' data-service-call='bookmark-add-button' ref={bookmarkRef} /> }
                <input
                    type='hidden'
                    name='SalesArticleVariantKey'
                    value={store.dataStore.orderModule.articleInfo.salesArticleVariantKey}
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='off'
                    spellCheck='false' />
                { store.dataStore.contextData.mpcIsAlive &&
                    <div
                        className='hidden scope-om-new'
                        data-service-call='mpc-compare-button'
                        data-slug={store.dataStore.breadcrumb?.category.seoSlug}
                        data-item-origin='OrderModal'
                        data-displayed-in-category-navkey={store.dataStore.breadcrumb?.category.navigationKey}
                        ref={mpcRef}
                    ></div>
                }
            </div>
        );
    })
);

export default CompareShareBookmark;
