import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import styles from './articlePrice.scss';
import { IBasePrice, IInjectedProps } from '../../../types';

import VatSwitcher from '../vatSwitcher/vatSwitcher';

export interface IPriceObject {
    grossValue: number;
    netValue: number;
    basePrice?: IBasePrice;
}

function Price({ priceString, isGlobal }: IPrice) {
    return (
        <div role='heading' data-testid='price-string' className={styles.articlePrice_priceString}>
            {
                isGlobal ?
                    <div className={styles.articlePrice_value} data-ge-price>{priceString}</div> :
                    <div className={styles.articlePrice_value}>{priceString}</div>
            }
        </div>
    );
}

interface IPrice {
    priceString: string;
    isGlobal: boolean;
}

const ArticlePrice = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const articlePrice = store.dataStore.orderModule.articlePrice;
        const orderModuleStore = store.orderModuleStore;
        const basePriceLocale = store.localization.basePriceLocale;
        const isSale = !!articlePrice.minimalOriginalPrice;
        const isGlobal = store?.isGlobal;

        const priceClassNames = classNames(styles.articlePrice_price, { [styles.articlePrice_price_global]: isGlobal });

        const basicPriceClassNames =
            isSale && !articlePrice?.flagAdvertisedAsCostFreeInSet
                ? [styles.articlePrice_main_price, styles.articlePrice_main_price_sale].join(' ')
                : styles.articlePrice_main_price;


        const shippingInfoRef = React.useRef<HTMLDivElement>(null);

        React.useEffect(() => {
            orderModuleStore.getShippingTextElement();

            if (shippingInfoRef.current && orderModuleStore.omShippingTextElement) {
                shippingInfoRef.current.appendChild(orderModuleStore.omShippingTextElement);

                store.setFocusableElement(shippingInfoRef.current.children[0]);
            }

            return orderModuleStore.returnShippingTextElement;
        }, []);

        return (
            <div className={styles.articlePrice_container}>
                <div className={styles.articlePrice_price_container}>
                    <div className={priceClassNames}>
                        {isSale && !articlePrice?.flagAdvertisedAsCostFreeInSet && (
                            <div data-testid='minimal-original-price'>
                                <s>
                                    <Price
                                        priceString={
                                            orderModuleStore.getPriceString(articlePrice?.minimalOriginalPrice) ?? ''
                                        }
                                        isGlobal={isGlobal}
                                    />
                                </s>
                            </div>
                        )}
                        <span className={basicPriceClassNames}>
                            <Price
                                priceString={orderModuleStore.getPriceString(articlePrice?.basicPrice) ?? ''}
                                isGlobal={isGlobal}
                            />
                        </span>
                        {!isGlobal &&
                            <div
                                role='shipping-info'
                                data-testid='om-articlePrice_shipping_info'
                                className={styles.articlePrice_shipping_info_container}
                                ref={shippingInfoRef}
                            />
                        }
                    </div>
                    {!isGlobal ?
                        <div className={styles.articlePrice_vat_container}>
                            <VatSwitcher />
                        </div> :
                        <div data-manipulate={store?.fetchedData.
                            orderModule.countryDependentContents.countryDependentContentsAttributeValue}
                            className={styles.articlePrice_total}
                            data-testid='om-countryDependentContents'
                        >
                            {store?.fetchedData.orderModule.
                                countryDependentContents.defaultCountryDependentContentsText 
                                && store?.fetchedData.orderModule.
                                    countryDependentContents.defaultCountryDependentContentsText.length > 0 
                                ? store?.fetchedData.orderModule.
                                    countryDependentContents.defaultCountryDependentContentsText
                            : store?.localization.totalProductPrice }
                        </div>
                    }
                </div>
                <>
                    {orderModuleStore.shouldRenderBasePrice && (
                        <div data-testid="base-price" className={styles.articlePrice_base_price}>
                            <div>{`${basePriceLocale}: `}</div>
                            {
                                isGlobal ?
                                    <div className={styles.articlePrice_base_price_price}
                                        data-ge-price>{orderModuleStore.basePriceString[0]}</div> :
                                    <div className={styles.articlePrice_base_price_price}>
                                        {orderModuleStore.basePriceString[0]}</div>
                            }
                            <div>{`/${orderModuleStore.basePriceString[1]}`}</div>
                        </div>
                    )}
                    {orderModuleStore.shouldRenderInfoTexts && (
                        <>
                            {orderModuleStore.infoTexts.map((info, index) => (
                                <div className={styles.articlePrice_info_text} key={info[1] + index} >
                                    <div>{info[0]}</div>
                                    {
                                        isGlobal ? 
                                            <div className={styles.articlePrice_info_text_price} data-ge-price>
                                                {info[1]}</div> :
                                            <div className={styles.articlePrice_info_text_price}>{info[1]}</div>
                                    }
                                    {
                                        info[2].length > 0 &&
                                        <>
                                            <div>{info[2]}</div>
                                            {
                                                isGlobal ?
                                                    <div className={styles.articlePrice_info_text_base_price}
                                                        data-ge-price>{info[3]}</div> :
                                                    <div className={styles.articlePrice_info_text_base_price}>
                                                        {info[3]}</div>
                                            }
                                            <div>{info[4]}</div>
                                        </>
                                    }
                                </div>
                            ))}
                        </>
                    )}
                </>
            </div>
        );
    })
);

export default ArticlePrice;
